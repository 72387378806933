module.exports = [{
      plugin: require('/builds/spsk/spsk.com.ua/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"fixed","zIndex":2,"top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(51,51,51,0.95)"},"content":{"position":"absolute","border":"none","background":"none","padding":0,"top":0,"bottom":0,"right":0,"left":0,"overflow":"auto","WebkitOverflowScrolling":"touch"}},"contentLabel":"Modal"}},
    },{
      plugin: require('/builds/spsk/spsk.com.ua/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/spsk/spsk.com.ua/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"wrapperStyle":"width: 100%;"}}]},
    },{
      plugin: require('/builds/spsk/spsk.com.ua/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
